import React from "react";
import { RotatingLines } from "react-loader-spinner";
import "./Loader.css";

export default function Loader({ isLoading }) {
  return (
    <div className="loading" hidden={!isLoading}>
      <div className="loader-container">
        {/* Rotating Lines Loader */}
        <RotatingLines
          visible={isLoading}
          width="96"
          strokeColor="#ffffff"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />

        {/* Overlay text in the middle */}
        <p className="loading-text">Please wait...</p>
      </div>
    </div>
  );
}
